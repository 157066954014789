<template>
  <MainStyled>
    <router-view />
  </MainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
const MainStyled = styled('div')`
  height: calc(100% - 1rem);
  overflow: hidden;
`
export default {
  components: {
    MainStyled,
  },
}
</script>
